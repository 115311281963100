.introduction {

    display: flex;

    .introduction-text, .profile {

        box-sizing: border-box;
        padding: 0 3%;

    }

    .introduction-text {

        width: 70%;

        .introduction-small {

            font-size: 26px;
            margin-bottom: 1em;

            span {
                -webkit-animation: wave 2.5s infinite;
                animation: wave 2.5s infinite;
                display: inline-block;
                font-size: 1.7rem;
            }

        }

        .introduction-large {

            font-weight: 600;
            font-size: 40px;
            line-height: 45px;
            margin-bottom: 0.5em;

            .introduction-highlight {
                color: red;
                font-weight: 900;
            }

        }

        .introduction-details {


        }

    }

    .profile {

        width: 30%;

        img {

            border: 20px solid black;
            border-radius: 50%;
            width: 100%;

        }

    }

}
.skills {

    padding-top: 1em;

    .skills-container {

        margin-bottom: 3em;
        padding: 0 1em;

        .skills-title {
            
            font-size: 2em;
            margin-bottom: 0.5em;

        }

        .skill-block {

            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1fr;

            .skill-item {

                @include border-radius-odd;
                @include box-shadow;
                color: #fff;
                font-size: 1.25em;
                padding: 1em 0;
                margin: 1em;
                text-align: center;

                .skill-icon {

                    color: #fff;
                    margin: 0 auto 0.5em;
                    width: 59.5px;

                    svg {
                        fill: #fff;
                        height: 55px;
                    }

                }

                &:nth-child(even) {
                    @include border-radius-even;
                }

            }

        }

        &.uxui {

            .skills-title {
                color: #1bc3b6;
                svg {
                    color: #1bc3b6;
                }
            }

            .skill-item {

                &.figma {
                    background-color: #a259ff;
                }

                &.adobexd {
                    background-color: #ff61f6;
                }

                &.sketch {
                    background-color: #fdad00;
                }

                &.uxpin {
                    background-color: black;
                }

            }

        }

        &.code {

            .skills-title {
                color: #00a6f4;
                svg {
                    color: #00a6f4;
                }
            }

            .skill-item {

                &.html {
                    background-color: #e44d26;
                }

                &.sass {
                    background-color: #cd669a;
                }

                &.javascript {
                    background-color: #e4a125;
                }

                &.react {
                    background-color: #61dafb;
                }

                &.node {
                    background-color: #419a35;
                }

                &.github {
                    background-color: black;
                }

            }

        }

        &.design {

            .skills-title {
                color: #b84d0b;
                svg {
                    color: #b84d0b;
                }
            }

            .skill-item {

                &.photoshop {
                    background-color: #30a8ff;
                }

                &.illustrator {
                    background-color: #ff9a00;
                }

                &.indesign {
                    background-color: #e94869;
                }

                &.aftereffects {
                    background-color: #9999ff;
                }

            }

        }

    }

}
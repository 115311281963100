.contact {

    padding: 0 1em;

    .cog-label {
        background: red;
    }

    textarea {
        height: 250px !important;
        resize: none !important;
    }

    button[type="submit"] {
        background-color: dodgerblue !important;
        border: none !important;

        &:hover {
            background-color: #61dafb !important;
        }
    }

}

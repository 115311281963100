.portfolio {

    .portfolio-container {

        display: flex;
        justify-content: space-around;

        .portfolio-item {

            background-size: cover;
            @include border-radius-odd;
            @include box-shadow;
            color: white;
            flex: 40% 1;
            max-width: 40%;
            text-align: center;
            text-shadow: 4px 4px 2px rgba(0,0,0,1);

            .inner-text {
                font-size: 1.9em;
                font-weight: 600;
                line-height: 1.2em;
                margin: 10% 4%;
                padding: 9% 0;
            }

            &.dev-design {
                background-image: url("../imgs/icon_portfolio_devDesign.jpg");

                &:hover {
                    background-image: url("../imgs/icon_portfolio_devDesign_hover.jpg");
                }

            }

            &.ux-ui {
                background-image: url("../imgs/icon_portfolio_uxui.jpg");

                &:hover {
                    background-image: url("../imgs/icon_portfolio_uxui_hover.jpg");
                }

            }

            &:hover {
                color: black;
                cursor: pointer;
                text-shadow: 1px 1px 0px rgba(255,255,255,1);
            }

            &:nth-child(even) {
                @include border-radius-even;
            }

        }

    }

}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html {
  font-size: 10px;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-size: 10px;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* introduction */
@keyframes wave {
  0%, 100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-12px) rotate(-10deg);
    transform: translateX(-12px) rotate(-10deg);
  }
  30% {
    -webkit-transform: translateX(6px) rotate(10deg);
    transform: translateX(6px) rotate(10deg);
  }
  45% {
    -webkit-transform: translateX(-6px) rotate(-5.5555555556deg);
    transform: translateX(-6px) rotate(-5.5555555556deg);
  }
  60% {
    -webkit-transform: translateX(3.6363636364px) rotate(3.3333333333deg);
    transform: translateX(3.6363636364px) rotate(3.3333333333deg);
  }
  75% {
    -webkit-transform: translateX(-2.1818181818px) rotate(-2deg);
    transform: translateX(-2.1818181818px) rotate(-2deg);
  }
}
/* section-title */
@keyframes blink {
  0% {
    opacity: 0;
  }
  24% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  74% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/* portfolioItem */
@keyframes slide {
  from {
    padding-left: 0px;
  }
  to {
    padding-left: 5px;
  }
}
/* footer */
@keyframes heartbeat {
  0%, 50%, 100% {
    transform: scale(1, 1);
  }
  30%, 80% {
    transform: scale(0.92, 0.95);
  }
}
.desktop {
  visibility: visible;
}

.mobile {
  visibility: hidden;
}

.mobileMenuButton {
  color: white;
  cursor: pointer;
  display: block;
  font-size: 2em;
  font-weight: 100;
  padding: 1em 0 1em 1em;
  position: absolute;
  right: 0.8em;
  top: 0.8em;
  z-index: 3;
}

.mobileMenu {
  background-color: black;
  -webkit-box-shadow: -8px 0px 17px 5px rgba(0, 0, 0, 0.42);
  box-shadow: -8px 0px 17px 5px rgba(0, 0, 0, 0.42);
  height: 1000px;
  padding-top: 4em;
  position: absolute;
  right: -300px;
  top: 5em;
  transition: right 0.5s ease;
  z-index: 1;
}
.mobileMenu nav ul {
  display: block;
}
.mobileMenu nav ul li {
  padding: 1em 1em 1em 5em;
  text-align: right;
}
.mobileMenu.active {
  right: 0;
}

@media only screen and (max-width: 1300px) {
  .desktop {
    visibility: hidden;
  }
  .mobile {
    visibility: visible;
  }
  body header .inner-header .container {
    width: 70%;
  }
  body header .inner-header .container nav ul {
    background: black;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 18em;
  }
  body header .inner-header .container nav ul li {
    border-color: #5e5e5e;
    padding: 1em;
    text-align: right;
  }
  body article {
    padding-top: 1em;
  }
}
@media only screen and (max-width: 1100px) {
  article .introduction .profile {
    width: 25%;
  }
}
@media only screen and (max-width: 1000px) {
  article .resume .resume-container .resume-item .resume-item-headline {
    display: block;
  }
  article .resume .resume-container .resume-item .resume-item-headline .job-employer {
    text-align: center;
    margin-bottom: 1em;
  }
  article .resume .resume-container .resume-item .resume-item-headline .job-title-and-date {
    display: flex;
    justify-content: center;
  }
  article .resume .resume-container .resume-item .resume-item-headline .job-title-and-date .job-title, article .resume .resume-container .resume-item .resume-item-headline .job-title-and-date .job-date {
    padding: 0 1em;
  }
  article .resume .resume-container .resume-item .job-highlights {
    grid-row-gap: 1em;
    grid-template-columns: repeat(2, 1fr);
  }
  article .education .education-container {
    display: block;
  }
  article .education .education-container .education-item {
    margin: 0 auto 1em;
    max-width: 309px;
  }
  article .portfolioProjectsList {
    display: block;
  }
}
@media only screen and (max-width: 800px) {
  article .introduction {
    display: block;
  }
  article .introduction .introduction-text {
    width: 100%;
  }
  article .introduction .introduction-text .introduction-small {
    text-align: center;
  }
  article .introduction .introduction-text .introduction-large {
    font-size: 38px;
  }
  article .introduction .introduction-text .introduction-details {
    margin-bottom: 2em;
  }
  article .introduction .profile {
    margin: 0 auto;
    width: 35%;
  }
  article .portfolio .portfolio-container {
    display: block;
  }
  article .portfolio .portfolio-container .portfolio-item {
    margin: 0 auto;
    max-width: 90%;
  }
  article .skills .skills-container .skills-title {
    text-align: center;
  }
  article .skills .skills-container .skill-block {
    grid-template-columns: repeat(2, 1fr);
  }
  article .resume .resume-and-linkedIn {
    display: block;
  }
  article .resume .resume-and-linkedIn a {
    margin: 0 auto 1em;
  }
  article .resume .resume-container .resume-item .resume-item-headline .job-title-and-date .job-title, article .resume .resume-container .resume-item .resume-item-headline .job-title-and-date .job-date {
    padding: 0 0.5em;
  }
}
.carouselContainer .closeButton:hover {
  cursor: pointer;
}
.carouselContainer .carousel .carousel-status {
  background: rgba(0, 0, 0, 0.75);
  font-size: 1em;
  right: 1em;
  top: 1em;
}
.carouselContainer .carousel .control-dots {
  display: none;
}
.carouselContainer .carousel .slide {
  -webkit-border-radius: 0 1em;
  -moz-border-radius: 0 1em;
  -ms-border-radius: 0 1em;
  border-radius: 0 1em;
  background: none;
  border: 1em solid black;
}
.carouselContainer .carousel .slide .legend {
  font-size: 1em;
  opacity: 0.75 !important;
}
.carouselContainer .carousel .thumbs-wrapper {
  margin-bottom: 0;
}
.carouselContainer .carousel .thumbs-wrapper .thumbs {
  text-align: center;
}

nav {
  min-height: 4em;
}
nav ul {
  display: flex;
  justify-content: space-evenly;
}
nav ul li {
  border-bottom: 2px solid black;
  color: white;
  cursor: pointer;
  font-weight: 100;
  font-size: 2em;
  padding-bottom: 2px;
}
nav ul li span {
  display: block;
  font-size: 0.5em;
  text-align: right;
}
nav ul li:hover {
  border-bottom: 2px solid red;
  color: red;
  font-weight: 200;
}

.introduction {
  display: flex;
}
.introduction .introduction-text, .introduction .profile {
  box-sizing: border-box;
  padding: 0 3%;
}
.introduction .introduction-text {
  width: 70%;
}
.introduction .introduction-text .introduction-small {
  font-size: 26px;
  margin-bottom: 1em;
}
.introduction .introduction-text .introduction-small span {
  -webkit-animation: wave 2.5s infinite;
  animation: wave 2.5s infinite;
  display: inline-block;
  font-size: 1.7rem;
}
.introduction .introduction-text .introduction-large {
  font-weight: 600;
  font-size: 40px;
  line-height: 45px;
  margin-bottom: 0.5em;
}
.introduction .introduction-text .introduction-large .introduction-highlight {
  color: red;
  font-weight: 900;
}
.introduction .profile {
  width: 30%;
}
.introduction .profile img {
  border: 20px solid black;
  border-radius: 50%;
  width: 100%;
}

.portfolio .portfolio-container {
  display: flex;
  justify-content: space-around;
}
.portfolio .portfolio-container .portfolio-item {
  background-size: cover;
  -webkit-border-radius: 0 1em;
  -moz-border-radius: 0 1em;
  -ms-border-radius: 0 1em;
  border-radius: 0 1em;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  color: white;
  flex: 40% 1;
  max-width: 40%;
  text-align: center;
  text-shadow: 4px 4px 2px rgb(0, 0, 0);
}
.portfolio .portfolio-container .portfolio-item .inner-text {
  font-size: 1.9em;
  font-weight: 600;
  line-height: 1.2em;
  margin: 10% 4%;
  padding: 9% 0;
}
.portfolio .portfolio-container .portfolio-item.dev-design {
  background-image: url("../imgs/icon_portfolio_devDesign.jpg");
}
.portfolio .portfolio-container .portfolio-item.dev-design:hover {
  background-image: url("../imgs/icon_portfolio_devDesign_hover.jpg");
}
.portfolio .portfolio-container .portfolio-item.ux-ui {
  background-image: url("../imgs/icon_portfolio_uxui.jpg");
}
.portfolio .portfolio-container .portfolio-item.ux-ui:hover {
  background-image: url("../imgs/icon_portfolio_uxui_hover.jpg");
}
.portfolio .portfolio-container .portfolio-item:hover {
  color: black;
  cursor: pointer;
  text-shadow: 1px 1px 0px rgb(255, 255, 255);
}
.portfolio .portfolio-container .portfolio-item:nth-child(even) {
  -webkit-border-radius: 1em 0;
  -moz-border-radius: 1em 0;
  -ms-border-radius: 1em 0;
  border-radius: 1em 0;
}

.portfolioProjectsList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}
.portfolioProjectsList .portfolioItem {
  -webkit-border-radius: 0 1em;
  -moz-border-radius: 0 1em;
  -ms-border-radius: 0 1em;
  border-radius: 0 1em;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  padding: 1em;
  margin: 1em;
}
.portfolioProjectsList .portfolioItem .project-name {
  color: white;
  font-size: 1.4em;
  font-weight: 600;
  line-height: 1em;
  margin-bottom: 0.5em;
}
.portfolioProjectsList .portfolioItem .project-name a {
  color: white;
  text-decoration: none;
}
.portfolioProjectsList .portfolioItem .project-name a:hover {
  -webkit-animation: slide 0.5s;
  animation: slide 0.5s;
}
.portfolioProjectsList .portfolioItem .iframeWrapper,
.portfolioProjectsList .portfolioItem .photoWrapper {
  margin: 1em 0;
}
.portfolioProjectsList .portfolioItem .iframeWrapper iframe,
.portfolioProjectsList .portfolioItem .iframeWrapper img,
.portfolioProjectsList .portfolioItem .photoWrapper iframe,
.portfolioProjectsList .portfolioItem .photoWrapper img {
  width: 100%;
}
.portfolioProjectsList .portfolioItem .iframeWrapper img,
.portfolioProjectsList .portfolioItem .photoWrapper img {
  cursor: pointer;
}
.portfolioProjectsList .portfolioItem .iframeWrapper iframe,
.portfolioProjectsList .portfolioItem .photoWrapper iframe {
  height: 250px;
}
.portfolioProjectsList .portfolioItem .project-details .top-details {
  font-size: 1.4em;
  line-height: 1.3em;
}
.portfolioProjectsList .portfolioItem .project-details .top-details span {
  font-weight: 600;
}
.portfolioProjectsList .portfolioItem .project-details .top-details .view-slides, .portfolioProjectsList .portfolioItem .project-details .top-details a {
  color: white;
  text-decoration: none;
}
.portfolioProjectsList .portfolioItem .project-details .top-details .view-slides:hover, .portfolioProjectsList .portfolioItem .project-details .top-details a:hover {
  -webkit-animation: slide 0.5s;
  animation: slide 0.5s;
}
.portfolioProjectsList .portfolioItem .project-details .top-details .view-slides {
  cursor: pointer;
  margin-bottom: 0.5em;
}
.portfolioProjectsList .portfolioItem .project-details .description {
  margin-bottom: 1em;
}
.portfolioProjectsList .portfolioItem:nth-child(even) {
  -webkit-border-radius: 1em 0;
  -moz-border-radius: 1em 0;
  -ms-border-radius: 1em 0;
  border-radius: 1em 0;
}
.portfolioProjectsList .portfolioItem:nth-child(4n+1) {
  background-color: #9999ff;
}
.portfolioProjectsList .portfolioItem:nth-child(4n+2) {
  background-color: #30a8ff;
}
.portfolioProjectsList .portfolioItem:nth-child(4n+3) {
  background-color: #419a35;
}
.portfolioProjectsList .portfolioItem:nth-child(4n+4) {
  background-color: #61dafb;
}
.portfolioProjectsList .portfolioItem:nth-child(4n+5) {
  background-color: #61dafb;
}
.portfolioProjectsList .portfolioItem:nth-child(4n+6) {
  background-color: #e4a125;
}
.portfolioProjectsList .portfolioItem:nth-child(4n+7) {
  background-color: #e44d26;
}
.portfolioProjectsList .portfolioItem:nth-child(4n+8) {
  background-color: #cd669a;
}
.portfolioProjectsList .portfolioItem:nth-child(4n+9) {
  background-color: #ff61f6;
}
.portfolioProjectsList .portfolioItem:nth-child(4n+10) {
  background-color: #a259ff;
}
.portfolioProjectsList .portfolioItem span.noshow {
  background-color: #a259ff;
  background-color: #ff61f6;
  background-color: #e44d26;
  background-color: #cd669a;
  background-color: #e4a125;
  background-color: #61dafb;
  background-color: #419a35;
  background-color: #30a8ff;
  background-color: #e94869;
  background-color: #9999ff;
}

.skills {
  padding-top: 1em;
}
.skills .skills-container {
  margin-bottom: 3em;
  padding: 0 1em;
}
.skills .skills-container .skills-title {
  font-size: 2em;
  margin-bottom: 0.5em;
}
.skills .skills-container .skill-block {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
}
.skills .skills-container .skill-block .skill-item {
  -webkit-border-radius: 0 1em;
  -moz-border-radius: 0 1em;
  -ms-border-radius: 0 1em;
  border-radius: 0 1em;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 1.25em;
  padding: 1em 0;
  margin: 1em;
  text-align: center;
}
.skills .skills-container .skill-block .skill-item .skill-icon {
  color: #fff;
  margin: 0 auto 0.5em;
  width: 59.5px;
}
.skills .skills-container .skill-block .skill-item .skill-icon svg {
  fill: #fff;
  height: 55px;
}
.skills .skills-container .skill-block .skill-item:nth-child(even) {
  -webkit-border-radius: 1em 0;
  -moz-border-radius: 1em 0;
  -ms-border-radius: 1em 0;
  border-radius: 1em 0;
}
.skills .skills-container.uxui .skills-title {
  color: #1bc3b6;
}
.skills .skills-container.uxui .skills-title svg {
  color: #1bc3b6;
}
.skills .skills-container.uxui .skill-item.figma {
  background-color: #a259ff;
}
.skills .skills-container.uxui .skill-item.adobexd {
  background-color: #ff61f6;
}
.skills .skills-container.uxui .skill-item.sketch {
  background-color: #fdad00;
}
.skills .skills-container.uxui .skill-item.uxpin {
  background-color: black;
}
.skills .skills-container.code .skills-title {
  color: #00a6f4;
}
.skills .skills-container.code .skills-title svg {
  color: #00a6f4;
}
.skills .skills-container.code .skill-item.html {
  background-color: #e44d26;
}
.skills .skills-container.code .skill-item.sass {
  background-color: #cd669a;
}
.skills .skills-container.code .skill-item.javascript {
  background-color: #e4a125;
}
.skills .skills-container.code .skill-item.react {
  background-color: #61dafb;
}
.skills .skills-container.code .skill-item.node {
  background-color: #419a35;
}
.skills .skills-container.code .skill-item.github {
  background-color: black;
}
.skills .skills-container.design .skills-title {
  color: #b84d0b;
}
.skills .skills-container.design .skills-title svg {
  color: #b84d0b;
}
.skills .skills-container.design .skill-item.photoshop {
  background-color: #30a8ff;
}
.skills .skills-container.design .skill-item.illustrator {
  background-color: #ff9a00;
}
.skills .skills-container.design .skill-item.indesign {
  background-color: #e94869;
}
.skills .skills-container.design .skill-item.aftereffects {
  background-color: #9999ff;
}

.resume {
  position: relative;
}
.resume .section-title {
  margin-bottom: 1em;
}
.resume .resume-and-linkedIn {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin: 1em 0 2em;
}
.resume .resume-and-linkedIn a {
  background-color: dodgerblue;
  color: white;
  border-radius: 1em;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  display: block;
  font-size: 1.4em;
  padding: 1em;
  text-align: center;
  text-decoration: none;
  width: 320px;
}
.resume .resume-and-linkedIn a:hover {
  background-color: #61dafb;
  box-shadow: none;
}
.resume .resume-container {
  color: white;
  position: relative;
  z-index: 1;
}
.resume .resume-container .resume-item {
  -webkit-border-radius: 0 1em;
  -moz-border-radius: 0 1em;
  -ms-border-radius: 0 1em;
  border-radius: 0 1em;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  margin-bottom: 2em;
  padding: 2em 1.4em;
}
.resume .resume-container .resume-item .resume-item-headline {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.resume .resume-container .resume-item .resume-item-headline .job-employer {
  font-size: 2em;
  font-weight: 600;
  line-height: 1.15em;
  text-shadow: 1px 2px 1px black;
}
.resume .resume-container .resume-item .resume-item-headline .job-employer span {
  display: block;
}
.resume .resume-container .resume-item .resume-item-headline .job-title-and-date {
  font-size: 1.25em;
}
.resume .resume-container .resume-item .resume-item-headline .job-title-and-date .job-title {
  display: flex;
  margin-bottom: 0.5em;
}
.resume .resume-container .resume-item .resume-item-headline .job-title-and-date .job-date {
  display: flex;
  font-style: italic;
}
.resume .resume-container .resume-item .resume-item-headline .job-title-and-date svg {
  font-size: 1.5em;
  margin-right: 10px;
  width: 2em;
}
.resume .resume-container .resume-item .job-highlights {
  color: black;
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  margin: 2em 0;
}
.resume .resume-container .resume-item .job-highlights li {
  background-color: white;
  border-radius: 1em;
  font-weight: 600;
  padding: 0.5em;
  text-align: center;
}
.resume .resume-container .resume-item .job-details {
  margin-bottom: 10px;
}
.resume .resume-container .resume-item .job-details a {
  color: white;
}
.resume .resume-container .resume-item .job-details strong {
  font-weight: 800;
}
.resume .resume-container .resume-item:nth-child(even) {
  -webkit-border-radius: 1em 0;
  -moz-border-radius: 1em 0;
  -ms-border-radius: 1em 0;
  border-radius: 1em 0;
}
.resume .resume-container .resume-item.scdhhs {
  background-color: #6c6acc;
}
.resume .resume-container .resume-item.graybear {
  background-color: #cb7343;
}
.resume .resume-container .resume-item.ironyard {
  background-color: #96cb43;
}
.resume .resume-container .resume-item.cyberwoven {
  background-color: #43cbc9;
}

.education .education-container {
  display: grid;
  grid-column-gap: 10em;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
}
.education .education-container .education-item {
  background-color: #B3A369;
  -webkit-border-radius: 0 1em;
  -moz-border-radius: 0 1em;
  -ms-border-radius: 0 1em;
  border-radius: 0 1em;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
  color: #FFF;
  display: flex;
  font-size: 1.25em;
  padding: 1em;
}
.education .education-container .education-item .education-icon {
  width: 100px;
}
.education .education-container .education-item .education-icon svg {
  fill: #FFF;
  height: 50px;
}
.education .education-container .education-item:nth-child(even) {
  -webkit-border-radius: 1em 0;
  -moz-border-radius: 1em 0;
  -ms-border-radius: 1em 0;
  border-radius: 1em 0;
}

.contact {
  padding: 0 1em;
}
.contact .cog-label {
  background: red;
}
.contact textarea {
  height: 250px !important;
  resize: none !important;
}
.contact button[type=submit] {
  background-color: dodgerblue !important;
  border: none !important;
}
.contact button[type=submit]:hover {
  background-color: #61dafb !important;
}

.hide {
  display: none;
}

body {
  background-color: white;
  color: black;
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-style: normal;
}
body .container {
  display: block;
  margin: 0 auto;
  max-width: 1024px;
}
body .App {
  overflow: hidden;
}
body .App header {
  left: 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
}
body .App header .inner-header {
  background-color: black;
  padding-top: 2.5%;
}
body .App header .inner-header img {
  cursor: pointer;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 13%;
}
body .App header svg {
  fill: black;
}
body .App article {
  font-size: 18px;
  line-height: 24px;
  position: relative;
  z-index: 1;
}
body .App article .section {
  margin-bottom: 2em;
  scroll-margin-top: 120px;
}
body .App article .section .section-title {
  font-size: 2em;
  font-weight: 600;
  line-height: 1em;
  margin-bottom: 2em;
  text-align: center;
}
body .App article .section .section-title span.code {
  background-color: black;
  color: white;
  display: inline-block;
  padding: 0.5em 1em;
  position: relative;
  transition: 1s;
}
body .App article .section .section-title span.code .blue {
  color: #189fff;
}
body .App article .section .section-title span.code .yellow {
  color: #ddddab;
}
body .App article .section .section-title span.code .red {
  color: #ce9077;
  letter-spacing: 2px;
}
body .App article .section .section-title span.code .cursor {
  animation: 1s infinite blink;
  color: #666;
  font-size: 1.5em;
  position: absolute;
  right: 24px;
  top: 21px;
}
body .App footer {
  font-size: 1.5em;
  padding-bottom: 50px;
  text-align: center;
}
body .App footer svg {
  animation: 1.5s ease 0s infinite heartbeat;
  fill: red;
  height: 20px;
  width: 30px;
}


/* introduction */

@keyframes wave {
    0%, 100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    15% {
        -webkit-transform: translateX(-12px) rotate(-10deg);
        transform: translateX(-12px) rotate(-10deg);
    }

    30% {
        -webkit-transform: translateX(calc(12px / 2)) rotate(10deg);
        transform: translateX(calc(12px / 2)) rotate(10deg);
    }

    45% {
        -webkit-transform: translateX(calc(-12px / 2)) rotate(calc(-10deg / 1.8));
        transform: translateX(calc(-12px / 2)) rotate(calc(-10deg / 1.8));
    }

    60% {
        -webkit-transform: translateX(calc(12px / 3.3)) rotate(calc(10deg / 3));
        transform: translateX(calc(12px / 3.3)) rotate(calc(10deg / 3));
    }

    75% {
        -webkit-transform: translateX(calc(-12px / 5.5)) rotate(calc(-10deg / 5));
        transform: translateX(calc(-12px / 5.5)) rotate(calc(-10deg / 5));
    }
}

/* section-title */

@keyframes blink{
    0%{opacity: 0;}
    24%{opacity: 0;}
    25%{opacity: 1;}
    74%{opacity: 1;}
    75%{opacity: 0;}
    100%{opacity: 0;}
}

/* portfolioItem */
@keyframes slide {
    from {
        padding-left: 0px;
    }
    to {
        padding-left: 5px;
    }
}

/* footer */

@keyframes heartbeat {
    0%, 50%, 100% { transform: scale(1, 1); }
    30%, 80% { transform: scale(0.92, 0.95); }
}


.education {

    .education-container {

        display: grid;
        grid-column-gap: 10em;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;

        .education-item {
            
            background-color: #B3A369;
            @include border-radius-odd;
            @include box-shadow;
            color: #FFF;
            display: flex;
            font-size: 1.25em;
            padding: 1em;

            .education-icon {

                width: 100px;

                svg {
                    fill: #FFF;
                    height: 50px;
                }

            }

            &:nth-child(even) {
                @include border-radius-even;
            }

        }

    }

}
@mixin border-radius-odd {
    -webkit-border-radius: 0 $radius;
    -moz-border-radius: 0 $radius;
    -ms-border-radius: 0 $radius;
    border-radius: 0 $radius;
}

@mixin border-radius-even {
    -webkit-border-radius: $radius 0;
    -moz-border-radius: $radius 0;
    -ms-border-radius: $radius 0;
    border-radius: $radius 0;
}

@mixin box-shadow {
    box-shadow: 3px 3px 8px rgba(0,0,0,.3);
}

@mixin slide-animation {
    -webkit-animation: slide 0.5s;
    animation: slide 0.5s;
}
.desktop {
    visibility: visible;
}

.mobile {
    visibility: hidden;
}

.mobileMenuButton {
    color: white;
    cursor: pointer;
    display: block;
    font-size: 2em;
    font-weight: 100;
    padding: 1em 0 1em 1em;
    position: absolute;
    right: 0.8em;
    top: 0.8em;
    z-index: 3;
}

.mobileMenu {
    background-color: black;
    -webkit-box-shadow: -8px 0px 17px 5px rgba(0,0,0,0.42); 
    box-shadow: -8px 0px 17px 5px rgba(0,0,0,0.42);
    height: 1000px;
    padding-top: 4em;
    position: absolute;
    right: -300px;
    top: 5em;
    transition: right 0.5s ease; 
    z-index: 1;

    nav {

        ul {

            display: block;

            li {

                padding: 1em 1em 1em 5em;
                text-align: right;

            }

        }
    }

    &.active {
        right: 0;
    }
}

@media only screen and (max-width: 1300px) {

    .desktop {
        visibility: hidden;
    }

    .mobile {
        visibility: visible;
    }

    body {

        header {

            .inner-header {

                .container {

                    width: 70%;

                    nav {

                        ul {

                            background: black;
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 18em;

                            li {
                                border-color: #5e5e5e;
                                padding: 1em;
                                text-align: right;
                            }
            
                        }

                    }

                }

            }

        }

        article {
            padding-top: 1em;
        }
        
    }

}

@media only screen and (max-width: 1100px) {

    article {

        .introduction {

            .profile {

                width: 25%;

            }

        }

    }

}

@media only screen and (max-width: 1000px) {

    article {

        .resume {
            
            .resume-container{
                
                .resume-item{
                    
                    .resume-item-headline{ 

                        display: block;
                        
                        .job-employer {

                            text-align: center;
                            margin-bottom: 1em;

                        }

                        .job-title-and-date {
                            display: flex;
                            justify-content: center;

                            .job-title, .job-date {

                                padding: 0 1em;

                            }

                        }
                    
                    }

                    .job-highlights {

                        grid-row-gap: 1em;
                        grid-template-columns: repeat(2, 1fr);

                    }

                }

            }

        }

        .education {

            .education-container {
        
                display: block;
        
                .education-item {
        
                    margin: 0 auto 1em;
                    max-width: 309px;
        
                }
        
            }
        
        }

        .portfolioProjectsList {

            display: block;
        
            .portfolioItem {
        
                .project-name {

                    a {

                    }
                }
        
                .iframeWrapper,
                .photoWrapper {
        
                }
        
                .project-details {
        
                }
        
            }
        
        }
        
        

    }

}

@media only screen and (max-width: 800px) {

    article {

        .introduction {

            display: block;

            .introduction-text {

                width: 100%;

                .introduction-small {

                    text-align: center;

                }

                .introduction-large {

                    font-size: 38px;

                }

                .introduction-details {

                    margin-bottom: 2em;

                }

            }

            .profile {

                margin: 0 auto;
                width: 35%;

            }

        }

        .portfolio {
            
            .portfolio-container {

                display: block;

                .portfolio-item {

                    margin: 0 auto;
                    max-width: 90%;

                }

            }

        }

        .skills { 
            
            .skills-container {

                .skills-title {

                    text-align: center;

                }

                .skill-block {

                    grid-template-columns: repeat(2, 1fr);
                    
                }

            }

        }

        .resume {
      
            .resume-and-linkedIn {

                display: block;

                a {
                    margin: 0 auto 1em;
                }

            }            

            .resume-container{
                
                .resume-item{
                    
                    .resume-item-headline{ 

                        .job-title-and-date {

                            .job-title, .job-date {

                                padding: 0 0.5em;

                            }

                        }
                    
                    }

                }

            }
            
        }

    }

}

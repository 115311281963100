.carouselContainer {

    .closeButton {
        &:hover {
            cursor: pointer;
        }
    }

    .carousel {

        .carousel-status {

            background: rgba(0,0,0,0.75);
            font-size: 1em;
            right: 1em;
            top: 1em;
        }

        .control-dots {
            display: none;
        }

        .slide {

            @include border-radius-odd;
            background: none;
            border: 1em solid black;

            .legend {
                font-size: 1em;
                opacity: 0.75 !important;
            }

        }

        .thumbs-wrapper {

            margin-bottom: 0;

            .thumbs {
                text-align: center;
            }

        }

    }

}
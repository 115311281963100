.portfolioProjectsList {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;

    .portfolioItem {
        @include border-radius-odd;
        @include box-shadow;
        box-sizing: border-box;
        padding: 1em;
        margin: 1em;

        .project-name {
            color: white;
            font-size: 1.4em;
            font-weight: 600;
            line-height: 1em;
            margin-bottom: 0.5em;

            a {
                color: white;
                text-decoration: none;

                &:hover {
                    @include slide-animation;
                }
            }
        }

        .iframeWrapper,
        .photoWrapper {

            margin: 1em 0;

            iframe,
            img {

                width: 100%;

            }

            img {
                cursor: pointer;
            }

            iframe {
                height: 250px;
            }

        }

        .project-details {

            .top-details {

                font-size: 1.4em;
                line-height: 1.3em;

                span {
                    font-weight: 600;
                }

                .view-slides, a {
                    color: white;
                    text-decoration: none;

                    &:hover {
                        @include slide-animation;
                    }
                }

                .view-slides {
                    cursor: pointer;
                    margin-bottom: 0.5em;
                }

            }

            .description {
                margin-bottom: 1em;
            }

        }

        &:nth-child(even) {
            @include border-radius-even;
        }

        &:nth-child(4n+1) {
            background-color: #9999ff;
        }

        &:nth-child(4n+2) {
            background-color: #30a8ff;
        }

        &:nth-child(4n+3) {
            background-color: #419a35;
        }

        &:nth-child(4n+4) {
            background-color: #61dafb;
        }

        &:nth-child(4n+5) {
            background-color: #61dafb;
        }

        &:nth-child(4n+6) {
            background-color: #e4a125;
        }

        &:nth-child(4n+7) {
            background-color: #e44d26;
        }

        &:nth-child(4n+8) {
            background-color: #cd669a;
        }

        &:nth-child(4n+9) {
            background-color: #ff61f6;
        }

        &:nth-child(4n+10) {
            background-color: #a259ff;
        }

        span.noshow {
            background-color: #a259ff;
            background-color: #ff61f6;
            background-color: #e44d26;
            background-color: #cd669a;
            background-color: #e4a125;
            background-color: #61dafb;
            background-color: #419a35;
            background-color: #30a8ff;
            background-color: #e94869;
            background-color: #9999ff;
        }
        
    }

}


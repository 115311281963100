@import "reset";
@import "variables";
@import "mixins";
@import "animations";
@import "responsive";
@import "carouselCustom";
@import "nav";
@import "introduction";
@import "portfolio";
@import "portfolioProjects";
@import "skills";
@import "resume";
@import "education";
@import "contact";

.hide {
    display: none;
}

body {
    background-color: white;
    color: black;
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-style: normal;

    .container {
        display: block;
        margin: 0 auto;
        max-width: 1024px;
    }

    .App {

        overflow: hidden;

        header {

            left:0;
            position: sticky;
            top: 0;
            width: 100%;
            z-index: 2;

            .inner-header {

                background-color: black;
                padding-top: 2.5%;

                img {

                    cursor: pointer;
                    left: 20px;
                    position: absolute;
                    top: 20px;
                    width: 13%;

                }

            }

            svg {

                fill: black;

            }

        }

        article {

            font-size: 18px;
            line-height: 24px;
            position: relative;
            z-index: 1;

            .section {

                margin-bottom: 2em;
                scroll-margin-top: 120px;
                
                .section-title {

                    font-size: 2em;
                    font-weight: 600;
                    line-height: 1em;
                    margin-bottom: 2em;
                    text-align: center;

                    span.code {
                        background-color: black;
                        color: white;
                        display: inline-block;
                        padding: 0.5em 1em;
                        position: relative;
                        transition: 1s;

                        .blue {
                            color: #189fff;
                        }

                        .yellow {
                            color: #ddddab;
                        }

                        .red {
                            color: #ce9077;
                            letter-spacing: 2px;
                        }

                        .cursor {
                            animation: 1s infinite blink;
                            color: #666;
                            font-size: 1.5em;
                            position: absolute;
                            right: 24px;
                            top: 21px;
                        }
                    }

                }

            }
        }

        footer {
            font-size: 1.5em;
            padding-bottom: 50px;
            text-align: center;

            svg {
                animation: 1.5s ease 0s infinite heartbeat;
                fill: red;
                height: 20px;
                width: 30px;
            }
        }

    }
}

nav {

    min-height: 4em;

    ul {

        display: flex;
        justify-content: space-evenly;

        li {

            border-bottom: 2px solid black;
            color: white;
            cursor: pointer;
            font-weight: 100;
            font-size: 2em;
            padding-bottom: 2px;

            span {

                display: block;
                font-size: 0.5em;
                text-align: right;

            }

            &:hover {
                
                border-bottom: 2px solid red;
                color: red;
                font-weight: 200;

            }

        }

    }

}
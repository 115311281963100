.resume {

    position: relative;

    .section-title {
        margin-bottom: 1em;
    }

    .resume-and-linkedIn {

        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin: 1em 0 2em;

        a {

            background-color: dodgerblue;
            color: white;
            border-radius: 1em;
            @include box-shadow;
            display: block;
            font-size: 1.4em;
            padding: 1em;
            text-align: center;
            text-decoration: none;
            width: 320px;

            &:hover {
                background-color: #61dafb;
                box-shadow: none;
            }

        }

    }

    .resume-container {

        color: white;
        position: relative;
        z-index: 1;

        .resume-item {
            @include border-radius-odd;
            @include box-shadow;
            box-sizing: border-box;
            margin-bottom: 2em;
            padding: 2em 1.4em;

            .resume-item-headline {

                    display: grid;
                    grid-template-columns: 2fr 1fr;
                    grid-template-rows: 1fr;
                    grid-column-gap: 0px;
                    grid-row-gap: 0px;

                .job-employer {
                    font-size: 2em;
                    font-weight: 600;
                    line-height: 1.15em;
                    text-shadow: 1px 2px 1px black;

                    span {
                        display: block;
                    }
                }

                .job-title-and-date {

                    font-size: 1.25em;

                    .job-title {
                        display: flex;
                        margin-bottom: 0.5em;
                    }

                    .job-date {
                        display: flex;
                        font-style: italic;
                    }

                    svg {
                        font-size: 1.5em;
                        margin-right: 10px;
                        width: 2em;
                    }

                }

            }

            .job-highlights {
                color: black;
                display: grid;
                grid-column-gap: 30px;
                grid-template-columns: repeat(4, 1fr);
                grid-template-rows: 1fr;
                margin: 2em 0;

                li {
                    background-color: white;
                    //border: 2px solid black;
                    border-radius: $radius;
                    font-weight: 600;
                    padding: .5em;
                    text-align: center;
                }
            }

            .job-details {
                margin-bottom: 10px;

                a {
                    color: white;
                }

                strong {
                    font-weight: 800;
                }
            }

            &:nth-child(even) {
                @include border-radius-even;
            }

            &.scdhhs {
                background-color: #6c6acc;
            }

            &.graybear {
                background-color: #cb7343;
            }

            &.ironyard {
                background-color: #96cb43;
            }

            &.cyberwoven {
                background-color: #43cbc9;
            }

        }

    }

}